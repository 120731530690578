module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Leadership Empowerment and Psychological Services Inc.","short_name":"L.E.A.P.S!","start_url":"/","backgroundColor":"#ffffff","themeColor":"#a04173","display":"standalone","icon":"static/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"b996f90d740b91e68abd038031b43139"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-173997146-1","head":false},
    },{
      plugin: require('../../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../gatsby-theme-seagull/gatsby-browser.js'),
      options: {"plugins":[],"sanityProjectId":"visrkzwr","sanityDataset":"production","sanityGraphqlTag":"default","siteName":"Seagull Theme Base","homePath":"/","googleAnalyticsID":"UA-167148080-1"},
    }]
