import React from 'react';
import { ThemeProvider } from 'styled-components';
// import { graphql, useStaticQuery } from 'gatsby';

const ThemeToggleContext = React.createContext();

export const useTheme = () => React.useContext(ThemeToggleContext);

export const MyThemeProvider = ({ children }) => {
  // const settingsData = useStaticQuery(graphql`
  //   {
  //     sanitySettings {
  //       defaultColorMode
  //     }
  //   }
  // `);

  // const sanityColorMode =
  //   settingsData &&
  //   settingsData.sanitySettings &&
  //   settingsData.sanitySettings.defaultColorMode &&
  //   settingsData.sanitySettings.defaultColorMode === 'dark'
  //     ? 'dark'
  //     : 'light'; TODO

  // console.log('sanityColorMode:', sanityColorMode);

  const [themeState, setThemeState] = React.useState({
    mode: 'light', // hmmmm
  });

  const toggle = () => {
    const mode = themeState.mode === 'light' ? `dark` : `light`;
    setThemeState({ mode });
    localStorage.setItem('color-mode', mode);
    window.__setTheme(mode);
  };

  React.useEffect(() => {
    // setThemeState({ mode: localStorage.getItem('color-mode') });
    if (document.body.classList.contains('color-mode-dark')) {
      setThemeState({ mode: 'dark' });
    } else {
      setThemeState({ mode: 'light' });
    }
  }, []);

  return (
    <ThemeToggleContext.Provider value={{ toggle }}>
      <ThemeProvider
        theme={{
          mode: themeState.mode,
        }}
      >
        <>{children}</>
      </ThemeProvider>
    </ThemeToggleContext.Provider>
  );
};

export default ThemeProvider;
