// import React from 'react';
// import { MyThemeProvider } from './src/theme/ThemeContext';

// export const onRenderBody = ({ setPreBodyComponents }, pluginOptions) => {
//   setPreBodyComponents([<MyThemeProvider key="theme-provider" />]);
// };

import React from 'react';
import App from './src/components/App';
import './src/styles/styles.css';

export const wrapRootElement = ({ element, props }) => (
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  <App {...props}>{element}</App>
);
